@page {
	size: A4;
	margin: 0;
}

html,
body {
	width: 210mm;
	height: 297mm;
}

body {
	padding: 12mm 10mm !important;
}

.accordion {
	&--item-trigger-icon,
	.btn,
	.responsive-object,
	iframe,
	embed,
	video {
		display: none;
	}

	&--item {
		&__no-print {
			display: none;
		}
	}

	&--item-content {
		height: auto !important;
		margin-top: 20px;
		padding-right: 0;
		& > :first-child {
			margin-top: 0;
		}
	}

	&--item-type {
		print-color-adjust: exact;
		-webkit-color-adjust: exact;
	}
}

.l-page__print-button,
.backlink,
footer,
.content-picker,
video,
iframe[src^="https://www.canva.com/"]
{
	display: none !important;
}

.l-page {
	margin: none;

	&__bottom-nav {
		display: none;
	}

	&__padder {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.html-block {
	&__aspect {
		&::before {
			content: none;
		}
	}
}

.richtext-image {
	max-width: 45% !important;
}

.richtext {
	.highlight {
		print-color-adjust: exact;
		-webkit-color-adjust: exact;
	}
}

.print-logo {
	display: block !important;
}

.l-site-header {
	position: relative;
	print-color-adjust: exact;
	-webkit-color-adjust: exact;
	padding: 4mm;
	background: url(../images/bg.svg);
	background-size: 100% auto;
	width: 100% !important;
	border-radius: var(--block-border-rad);
	height: auto;
	&:after,
	&__menu-trigger,
	& .l-navigation {
		display: none !important;
	}
}
